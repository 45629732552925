<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap pa-3>
      <v-flex lg4 sm12 py-0>        
        <v-text-field
          label="Mã bao hàng"
          v-model="currentItem.PackageCode"
          class="input-group--focused pt-0"
          :readonly="currentItem.ID > 0"
          @keydown.enter="onChangePackage"
          autofocus
        ></v-text-field>
      </v-flex>
      <v-flex lg8 sm12 py-0>
      </v-flex>
      <v-flex lg4 sm12 py-0>
        <v-text-field
          label="Ngày nhập kho"
          :value="currentItem.ImDate | display_date"
          class="input-group--focused font-weight-bold"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex lg8 sm12 py-0>
      </v-flex>
      <v-flex lg4 sm12  py-0>
        <h-currency-input
          label="Tổng phí ship"
          v-model="currentItem.Amount"
          :decimal="1"
          class="input-group--focused"
          readonly
        ></h-currency-input>
      </v-flex>
      <v-flex lg4 sm12  py-0>
        <h-currency-input
          label="Tổng phí ship đã nhập"
          v-model="currentItem.ReceivedAmount"
          :decimal="1"
          showClass="input-group--focused font-weight-bold text-red"
          readonly
        ></h-currency-input>
      </v-flex>
      <v-flex lg4 sm12  py-0>
      </v-flex>
      <v-flex lg4 sm12  py-0>
        <h-currency-input
          label="Số kiện (TQ)"
          v-model="currentItem.NumberOfPack"
          :decimal="0"
          class="input-group--focused"
          readonly
        ></h-currency-input>
      </v-flex>
      <v-flex lg4 sm12 py-0>
        <h-currency-input
          label="Số kiện đã nhập"
          v-model="currentItem.ReceivedPacks"
          :decimal="0"
          showClass="input-group--focused font-weight-bold text-red"
          color="red darken-2"
          readonly
        ></h-currency-input>
      </v-flex>
      <v-flex lg4 sm12 py-0>
      </v-flex>
      <v-flex lg4 sm12 py-0>
        <v-text-field
          label="Tổng cân nặng (TQ)"
          type="number"
          v-model="currentItem.Weight"
          class="input-group--focused"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex lg4 sm12 py-0>
        <v-text-field
          label="Tổng cân nặng đã nhập"
          type="number"
          v-model="currentItem.ReceivedWeight"
          class="input-group--focused font-weight-bold text-red"
          readonly
        ></v-text-field>
      </v-flex>
      <v-flex lg4 sm12 py-0>
      </v-flex>
      <!-- <v-flex lg4 sm12 py-0>
        <v-checkbox label="Bắn mã nhập kho" v-model="importBarcode"></v-checkbox>
      </v-flex> -->
      <v-flex lg8 sm12 py-0>
        <v-checkbox label="Nhập cân nặng/kích thước" v-model="importWeight"></v-checkbox>
      </v-flex>
      <v-flex lg4 sm12 py-0>
      </v-flex>
      <!-- <v-flex lg12 md12 sm12 xs12 py-0>
        <v-text-field
          label="Nhập mã bill (enter)"
          ref="trancode"
          v-model.lazy="transactionCode"
          class="input-group--focused pt-0"
          @keyup.enter="codeChanged"
          :disabled="readOnly"
        ></v-text-field>
      </v-flex> -->
      <v-flex lg12 md12 sm12 xs12 pa-0>
        <hpo-filter :headers="headers" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
        <v-data-table
          :headers="headers"
          :items="currentItem.Packages"
          :options.sync="pagination"
          :server-items-length="currentItem.Packages ? currentItem.Packages.length : 0"
          :footer-props="items_per_page"
          :must-sort="true"
          :loading="loading"
          :mobile-breakpoint="100"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td v-bind:class="item.OrderID == null ? 'text-red' : ''">{{ item.TransactionCode }}</td>
              <td v-if="system_type == 'order' && !item.OrderID"></td>
              <td v-else-if="system_type == 'order' && item.PackageType == 1"><a target="_blank" :href="'/ky-gui/' + item.OrderID">KG{{item.OrderID}}</a></td>
              <td v-else-if="system_type == 'order'"><a target="_blank" :href="'/don-hang/' + item.OrderID">MD{{item.OrderID}}</a></td>
              <td>{{ item.Username }}</td>
              <td class="text-end">{{ item.ChinaWeight | currency('', 2)}}</td>
              <td class="text-end" v-if="item.ChinaLength>0">{{item.ChinaLength}}x{{item.ChinaWidth}}x{{item.ChinaHeight}}</td>
              <td class="text-end" v-else></td>
              <td class="text-end" v-if="item.Status == 5 && importWeight">{{ item.FeeShipVND | currency }}</td>
              <td class="text-end" v-else-if=" importWeight">
                <v-edit-dialog
                  :return-value.sync="item.FeeShipVND"
                  large
                  lazy
                  persistent
                  @save="saveTranWeight(item)"
                >
                  <div>{{ item.FeeShipVND | currency }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.FeeShipVND"
                      type="number"
                      label="Phí khác"
                      step="1"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-end" v-if="item.Status == 5 && importWeight">{{ item.Weight | currency('', 2)}}</td>
              <td class="text-end" v-else-if="importWeight">
                <v-edit-dialog
                  :return-value.sync="item.Weight"
                  large
                  lazy
                  persistent
                  @save="saveTranWeight(item)"
                >
                  <div>{{ item.Weight | currency('', 2)}}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.Weight"
                      type="number"
                      label="Cân nặng"
                      step=".1"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-end" v-if="item.Status == 5 && importWeight">{{ item.RealNumberOfPack}}</td>
              <td class="text-end" v-else-if="importWeight">
                <v-edit-dialog
                  :return-value.sync="item.RealNumberOfPack"
                  large
                  lazy
                  persistent
                  @save="saveTranWeight(item)"
                >
                  <div>{{ item.RealNumberOfPack}}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.RealNumberOfPack"
                      type="number"
                      label="Số kiện"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-end" v-if="item.Status == 5 && importWeight">{{ item.Length}}</td>
              <td class="text-end" v-else-if="importWeight">
                <v-edit-dialog
                  :return-value.sync="item.Length"
                  large
                  lazy
                  persistent
                  @save="saveTranWeight(item)"
                >
                  <div>{{ item.Length}}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.Length"
                      type="number"
                      label="Chiều dài (cm)"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-end" v-if="item.Status == 5 && importWeight">{{ item.Width}}</td>
              <td class="text-end" v-else-if="importWeight">
                <v-edit-dialog
                  :return-value.sync="item.Width"
                  large
                  lazy
                  persistent
                  @save="saveTranWeight(item)"
                >
                  <div>{{ item.Width}}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.Width"
                      type="number"
                      label="Chiều rộng (cm)"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-end" v-if="item.Status == 5 && importWeight">{{ item.Height}}</td>
              <td class="text-end" v-else-if="importWeight">
                <v-edit-dialog
                  :return-value.sync="item.Height"
                  large
                  lazy
                  persistent
                  @save="saveTranWeight(item)"
                >
                  <div>{{ item.Height}}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.Height"
                      type="number"
                      label="Chiều cao (cm)"
                      single-line
                      autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>
              <td class="text-end" v-if="importWeight">{{ item.Volume | currency('', 3)}}</td>
              <td class="text-end" v-if="importWeight">{{ item.WeightByVolume | currency('', 2)}}</td>
              <td class="truncate">
                <v-edit-dialog
                :return-value.sync="item.StoreVNNote"
                large
                lazy
                persistent
                @save="saveNoteItem(item)"
                >
                    <v-tooltip max-width="250" color="light-green lighten-5 black--text">
                        <template v-slot:activator="{ on }">
                            <span v-on="on">{{ item.StoreVNNote }}</span>
                        </template>
                        <span>{{ item.StoreVNNote }}</span>
                    </v-tooltip>
                    <template v-slot:input>
                        <v-textarea
                        v-model="item.StoreVNNote"
                        label="Ghi chú"
                        autofocus
                        ></v-textarea>
                    </template>
                </v-edit-dialog>
              </td>
              <td class="text-center">
                <v-icon medium class="mr-2" color="red darken-2" @click="printTransaction(item.Weight, item.WeightByVolume, item.Length, item.Width, item.Height, item.TransactionCode, item.Username, item.OrderID, item.NumberOfCode)">fa-print</v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:body.append v-if="currentItem.PackageSummary">
            <tr class="table-summary">
                <td class="font-weight-bold">TỔNG</td>
                <td class="font-weight-bold text-end" v-if="system_type == 'order'"></td>
                <td class="font-weight-bold text-end"></td>
                <td class="font-weight-bold text-end">{{ currentItem.PackageSummary.ChinaWeight | currency('',2)}}</td>
                <td class="font-weight-bold text-end"></td>
                <td class="font-weight-bold text-end" v-if="importWeight">{{ currentItem.PackageSummary.FeeShipVND | currency }}</td>
                <td class="font-weight-bold text-end" v-if="importWeight">{{ currentItem.PackageSummary.Weight | currency('',2)}}</td>
                <td class="font-weight-bold text-end" v-if="importWeight">{{ currentItem.PackageSummary.RealNumberOfPack | currency}}</td>
                <td class="font-weight-bold text-end" v-if="importWeight"></td>
                <td class="font-weight-bold text-end" v-if="importWeight"></td>
                <td class="font-weight-bold text-end" v-if="importWeight"></td>
                <td class="font-weight-bold text-end" v-if="importWeight">{{ currentItem.PackageSummary.Volume | currency('',3)}}</td>
                <td class="font-weight-bold text-end" v-if="importWeight">{{ currentItem.PackageSummary.WeightByVolume | currency('',2)}}</td>
                <td class="font-weight-bold text-end"></td>
                <td class="font-weight-bold text-end"></td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-overlay :value="appLoading" z-index="999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showDialogTrancode" max-width="300px" :persistent="isMobile" :fullscreen="isMobile" :hide-overlay="isMobile" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="oee-theme" v-if="isMobile">
            <v-btn icon dark @click="showDialogTrancode = false">
              <v-icon>fa-times-circle</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Mã bill</v-toolbar-title>
        </v-toolbar>
        <v-card-title v-else>
          <span class="title">Mã bill</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xl fluid>
            <v-layout row wrap>
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <v-checkbox label="Nhập cân nặng" v-model="useWeight"></v-checkbox>
                <v-checkbox label="Nhập kích thước" v-model="useVolume"></v-checkbox>
             </v-flex>
            </v-layout>
            <v-layout row wrap>
              <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"  v-if="showDialogTrancode && isMobile && newTransaction.TransactionCode == ''"></StreamBarcodeReader>              
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume || useWeight">
                <v-text-field class="input-group--focused" ref="popup_trancode" autofocus label="Mã bill" v-model="newTransaction.TransactionCode" @keydown.enter="focusNext"></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-else>
                <v-text-field class="input-group--focused" ref="popup_trancode" autofocus label="Mã bill" v-model="newTransaction.TransactionCode" @keydown.enter="saveNewTrancode"></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useWeight && !useVolume">
                <h-currency-input class="input-group--focused" v-model="newTransaction.Weight" label="Cân nặng" :decimal="1" @press_enter="saveNewTrancode"></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useWeight && useVolume">
                <h-currency-input class="input-group--focused" v-model="newTransaction.Weight" label="Cân nặng" :decimal="1" @press_enter="focusNext"></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume">
                <h-currency-input class="input-group--focused" v-model="newTransaction.Length" label="Chiều dài (cm)" :decimal="0" @press_enter="focusNext"></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume">
                <h-currency-input class="input-group--focused" v-model="newTransaction.Width" label="Chiều rộng (cm)" :decimal="0" @press_enter="focusNext"></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume">
                <h-currency-input class="input-group--focused" v-model="newTransaction.Height" label="Chiều cao (cm)" :decimal="0" @press_enter="saveNewTrancode"></h-currency-input>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" class="mt-8" v-if="!isMobile" @click="showDialogTrancode = false">Đóng</v-btn>
          <v-btn color="red darken-1 white--text"  class="mt-8" :block="isMobile" @click="saveNewTrancode">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import _ from "lodash";
import { getCurrentTime, focusNextElement, sendErrorNotice, isMobileAgent } from "@/commons/utils";
import { print_transaction } from "@/commons/hpod";
import { StreamBarcodeReader } from "vue-barcode-reader";
import Filtering from "@/components/Filtering";

export default {
  components: {
    StreamBarcodeReader,
    "hpo-filter": Filtering
  },
  data() {
    return {
      appLoading: false,
      importBarcode: false,
      importWeight: false,
      useVolume: false,
      useWeight: false,
      focusNext: null,
      transactionCode: "",
      system_type: configs.SYSTEM_TYPE,
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      pagination: {...configs.PAGINATION,  sortBy: ["ID"] },
      items_per_page: {...configs.ITEMS_PER_PAGE},
      currentTime: getCurrentTime(),
      showDialogTrancode: false,
      newTransaction: {
        TransactionCode: '',
        Width: 0,
        Length: 0,
        Height: 0,
        Weight: 0,
      },
      filterConditions: [],
      quickFilterConditions: []
    };
  },
  props: ["dialogPackageDetail", "readOnly"],
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : [];
        this.refreshData();
      },
      deep: true
    },
    packageId() {
      if(this.packageId > 0) {
        this.$router.push({
        name: "StoreManagerVNDetail",
        params: {
          Pid: this.packageId,
          title: "Chi tiết nhập kho"
        }
      });
      this.refreshData();
      }
      this.appLoading = false;
    }
  },
  computed: {
    ...mapState({
      currentItem: state => state.bigPackage.selected,
      totalPackage: state => {
        state.bigPackage.selected.Packages
          ? state.bigPackage.selected.Packages.length
          : 0;
      },
      loading: state => state.bigPackage.loading,
      trancodeDetail: state => state.bigPackage.trancodeDetail,
      packages: state => state.bigPackage.notTransit
    }),
    isMobile: isMobileAgent,
    packageId() {
      return this.currentItem && this.currentItem.ID > 0
        ? this.currentItem.ID
        : Number(this.$route.params.Pid);
    },
    headers() {
      let temp_headers = [
        {
          text: "Mã bill",
          value: "TransactionCode",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Khách hàng",
          value: "Username",
          quickSearch: true,
          filterable: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Cân nặng TQ",
          value: "ChinaWeight",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Kích thước TQ",
          value: "ChinaLength",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        }
      ];

      if(configs.SYSTEM_TYPE == 'order') {
        temp_headers.splice(1, 0, {
          text: "Mã đơn hàng",
          value: "OrderID",
          quickSearch: true,
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        });
      }      

      if(this.importWeight) {
        temp_headers.push({
          text: "Phí khác",
          value: "FeeShipVND",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        });
        temp_headers.push({
          text: "Cân nặng",
          value: "Weight",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
        temp_headers.push({
          text: "Số kiện",
          value: "RealNumberOfPack",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
        temp_headers.push({
          text: "Chiều dài (cm)",
          value: "Length",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
        temp_headers.push({
          text: "Chiều rộng (cm)",
          value: "Width",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
        temp_headers.push({
          text: "Chiều cao (cm)",
          value: "Width",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
        temp_headers.push({
          text: "Khối",
          value: "Volume",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
        temp_headers.push({
          text: "Cân quy đổi",
          value: "WeightByVolume",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        })
      }

      temp_headers.push({
        text: "Ghi chú",
        value: "StoreVNNote",
        filterable: true,
        quickSearch: true,
        dataType: configs.DATA_TYPE["String"]
      })
      temp_headers.push({
        text: "Thao tác",
        value: "",
        align: "center",
        sortable: false,
        filterable: false
      })
      
      return temp_headers;
    }
  },
  mounted() {
    this.refreshData();
    this.getListPackageNotTran();
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_SAVE_DATA", this.dialogTrancode);
    this.$root.$on("REQUEST_REFRESH_DATA", this.refreshData);
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
  },
  created() {
    this.focusNext = focusNextElement;
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_SAVE_DATA", this.dialogTrancode);
    this.$root.$off("REQUEST_REFRESH_DATA", this.refreshData);
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
  },
  methods: {    
    printTransaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode) {
        print_transaction(weight, weight_v2, length, width, height, transactionCode, customerName, orderId, numberOfCode);
    },
    codeChanged() {
      this.addNewTrancode();
      this.filterConditions = [];
      this.quickFilterConditions = [];
      this.refreshData();
    },
    refreshData() {
      if (this.packageId) {
        let pr = {
          pagination: { ...this.pagination, itemsPerPage: 1000000 },
          filter: this.filterConditions,
          quickFilter: this.quickFilterConditions
        };
        if(this.transactionCode.trim() != '') {
          pr.filter.push({
            column: "TransactionCode",
            value: this.transactionCode.trim(),
            condition: "contain"
          });
        }
        this.$store.dispatch("bigPackage/getDetail", {'id': this.packageId, 'param': pr});
      }
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.refreshData();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.refreshData();
    },
    onChangePackage() {
      this.appLoading = true;
      let selectedItem = _.find(this.packages, { PackageCode: this.currentItem.PackageCode.trim() });
      if (selectedItem) {
        this.currentItem.ID = selectedItem.ID;
        this.saveData();
      }
      else {
        this.appLoading = false;
        sendErrorNotice('Không tìm thấy mã bao hàng.');
      }
    },
    getListPackageNotTran() {
      this.$store.dispatch("bigPackage/getListNotTransit");
    },
    saveTranWeight(item) {
      this.editTransCode(item);
    },
    saveNoteItem(item) {
      this.$store.dispatch("orderTransaction/setDetail", {'data': {'StoreVNNote': item.StoreVNNote}, 'id': item.ID});
    },
    createData(username = "") {
      this.getListPackageNotTran();
      this.$store.commit("bigPackage/setDetail", {
        data: {
          ID: 0,
          PackageCode: '',
          Weight: 0,
          Volume: 0,
          Amount: 0
        }
      });
      this.$router.push({
          name: "StoreManagerVNDetail",
          params: {
          Pid: 'nhap-bao-moi',
          title: 'Nhập bao mới'
          }
      });
    },
    saveData() {
      this.$store.dispatch("bigPackage/setDetail", {
        data: {
          ReceivedWeight: this.currentItem.ReceivedWeight,
          RealWeight: this.currentItem.RealWeight,
          ReceivedPacks: this.currentItem.ReceivedPacks,
          Status: configs.BIG_PACKAGE_STATUS_ENUM.NHAP_KHO_VN
        },
        id: this.currentItem.ID
      });
      this.appLoading = false;
    },
    editTransCode: _.debounce(
      function(item) {
        var data = {
          ID: item.ID,
          OrderID: item.OrderID,
          PackageType: item.PackageType,
          RealNumberOfPack: item.RealNumberOfPack,
          FeeShipVND: item.FeeShipVND,
          Weight: item.Weight,
          Length: item.Length,
          Width: item.Width,
          Height: item.Height,
          BigPackageID: item.BigPackageID,
          TransactionView: 'store_vn'
        };
        data.ModifiedBy = this.$store.state.authentication.user.username;
        this.$store.dispatch("orderTransaction/setDetail", { 'data': data, 'id': item.ID });
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),
    dialogTrancode() {
      this.showDialogTrancode = true;
      this.$nextTick(() => this.$refs.popup_trancode.focus());
    },
    addNewTrancode: _.debounce(
      function() {
        if(this.importBarcode && this.transactionCode.trim() != '') {
          this.$store.dispatch("bigPackage/addNewTrancode", {'data': {
            TransactionCode: this.transactionCode.trim(),
            Status: configs.SYSTEM_TYPE == 'order' ? configs.PACKAGE_STATUS_ENUM.DA_NHAP_KHO_PHAT : configs.PACKAGE_STATUS_ENUM.DA_VE_VN,
            BigPackageID: this.packageId
          }});
          this.transactionCode = '';
        }
      },
      1000,
      {
        leading: false,
        trailing: true
      }
    ),
    saveNewTrancode() {
      if(this.newTransaction.TransactionCode != '') {
        if(!this.useWeight) {
          delete this.newTransaction.Weight;
        }
        if(!this.useVolume) {
          delete this.newTransaction.Length;
          delete this.newTransaction.Width;
          delete this.newTransaction.Height;
        }
        this.$store.dispatch("bigPackage/addNewTrancode", {'data': {
          ...this.newTransaction,
          Status: configs.SYSTEM_TYPE == 'order' ? configs.PACKAGE_STATUS_ENUM.DA_NHAP_KHO_PHAT : configs.PACKAGE_STATUS_ENUM.DA_VE_VN,
          BigPackageID: this.packageId
        }});
      }
      this.newTransaction = {
        TransactionCode: '',
        Width: 0,
        Length: 0,
        Height: 0,
        Weight: 0,
      };
      this.$nextTick(() => this.$refs.popup_trancode.focus());
    },
    onDecode (data) {
      this.newTransaction.TransactionCode = data;
    },
    onLoaded() {}
  }
};
</script>
